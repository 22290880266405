@media(max-width:1340px){
    .logo{
        margin-left: 20px;
    }

    .content_midle{
        margin: 0 20px;
    }
    .footer{
        width: auto;
        margin: 0 20px;
    }
}

@media(max-width:1250px){
    .img-screen{
        width: 665px;
        height: -webkit-fill-available;
    }

    .footer_title{
        max-width: 600px;
    }

    .footer_sub-title{
        max-width: 600px;
    }
}

@media(max-width:1150px){
    .img-screen{
        width: 565px;

    }
}

@media(max-width:1050px){
    .img-screen{
        width: 465px;
    }
}

@media(max-width:950px){
    .cost{
        width: 255px;
        height: 315px; 
        padding: 35px 34px;
        
        &_title{
            width: 272px;
            font-size: 20px;
        }

        &_button{
            height: 183px;
            &>button{
                width: 255px;
                height: 39px;
                margin: 11px 0;
            }

            &__act{
                height: 272px;
            }
        }

        &__act{
            height: 354px;
        }
    }

    #imputCost{
        width: 211px;
        height: 11px;
        border: 1px solid #0057B8;
        border-radius: 34px;
        background-color: transparent;
        outline: none;
        padding: 13px 21px;
        &::placeholder{
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 11px;
            color: #0057B84D;
        }
    
    }
    .case{
        width: 325px;
        height: 360px;
        top: -120px;
        &>span:first-child{
            font-size: 25px;
        }
        &>span:last-child{
            font-size: 25px;
        }
    }

    .img-screen{
        margin: 0 0;
    }

    .footer{
        position: relative;
        top: -210px;
    }
}

@media(max-width:850px){
    body{
        background-image: url(../img/background-mini.png);
        background-size: 100% 150%;
    }

    .img-screen{
        position: relative;
        width: 339px;
        height: 231px;
    }

    .logo{
        margin-bottom: 16px
    }

    .logoB{
        height: 32px;
    }

    .content{
        margin: 34px auto;
        &_midle{
            margin: 0 auto;
        }
    }

    .cost{
        top: 10.5%;
    }

    .case{
        top: 0;
        height: 715px;
        &>span:first-child{
            text-align: end;
        }

        &>span:last-child{
            text-align: start;
            margin-left: 0;
        }
    }

    .footer{
        top: 164px;

        &_title{
            font-size: 16px;
        }

        &_sub-title{
            font-size: 12px;  
        }
    }
}